<template>
  <div class="partner-page">
    <h1>Partner panel</h1>
    <div v-if="this.$store.state.SAuth?.access?.isPartner">
      <router-link to="/place/partner">My gym</router-link>
    </div>
  </div>
</template>

<script>

export default {

  async mounted() {

    if (!await this.checkPartner())
      return;

    this.setBreadcrumbs();
  },

  methods: {

    setBreadcrumbs() {

      this.$emit("setBreadcrumbs", [
        'Partner panel'
      ]);
    }
  }

}
</script>

<style scoped>

</style>